@import "./responsive";
@import "./modernize-vars";

button.modernize,
.button.modernize {
  @include responsive-flex;

  height: 40px;
  font-size: 14px;
  padding: 10px 14px;
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  // outline-width: 0;
  margin: 1px;

  &:focus {
    outline-offset: 4px;
  }

  &:hover {
    background-color: transparent;
  }

  i {
    margin-right: 6px;
  }
}

button.modernize.primary,
.button.modernize.primary {
  background-color: $blue-dark;
  border: none;

  &:hover {
    background-color: $celadon-blue-dark;
  }
}

button.modernize.secondary,
.button.modernize.secondary {
  background-color: transparent;
  color: $celadon-blue-dark;
  border: solid 1px $celadon-blue-dark;

  &:hover,
  &:focus {
    color: $blue-gray-800;
    border-color: $blue-gray-800;
    opacity: 1;
    background: rgba(2, 126, 176, 0.05);
  }

  &:active {
    background: rgba(2, 126, 176, 0.1);
  }
}

button.modernize.link,
.button.modernize.link {
  color: $celadon-blue-dark;
  border: none;
  padding-left: 7px;
  padding-right: 7px;

  &:hover {
    background: rgba(2, 126, 176, 0.05);
  }

  &:active {
    background: rgba(2, 126, 176, 0.1);
  }
}

// A button with no styling
button.modernize.reset,
.button.modernize.reset {
  height: auto;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 0;
  border: none;
  margin: 0;
}

a.modernize.grey-link {
  color: $font-black;

  &:hover {
    color: $blue-dark;
    text-decoration: none;
  }
}

a.modernize.light-grey-link {
  color: $grey-dark;

  &:hover {
    color: $blue-dark;
    text-decoration: none;
  }
}

fieldset.modernize {
  border: none;
}

// Override icon styles
[class^="icon-"].modernize,
[class*=" icon-"].modernize {
  width: auto;
  height: auto;
  line-height: auto;
  background: none;
}

@mixin menu-styles {
  li {
    display: flex;
    align-items: center;

    i {
      margin-right: 12px;
      font-size: 16px;
    }

    a {
      display: inline-table;
      width: 100%;
      padding: 12px 16px;
      height: 100%;
    }
  }

  hr {
    margin: 7px 16px;
    color: $grey-mid;
  }
}

.modernize-dropdown-wrapper {
  display: flex;
  position: relative;
}
.modernize-dropdown-trigger:focus {
  outline: none;
  box-shadow: 0px 0px 0px 1px #ffffff, 0px 0px 0px 3px rgba(2, 126, 176, 0.7);
}
.modernize-dropdown {
  @include menu-styles;
  position: absolute !important;
  background-color: white;
  border-radius: 4px;
  padding: 8px 0;
  box-shadow: 0 3.8px 11px 0 rgba(0, 0, 0, 0.19), 0 0.5px 5px 0 rgba(0, 0, 0, 0.04);
  width: 240px;
  list-style: none;
  margin: 0;
  font-family: $font-family;
  opacity: 0;
  visibility: hidden;
  transition: transform 0.15s, opacity 0.15s, visibility 0s linear 0.15s;
  transform: scale(0.95);

  &.visible {
    visibility: visible;
    opacity: 1;
    transform: none;
    transition-delay: 0s;
  }
}

.modernize-menu {
  @include menu-styles;
}
