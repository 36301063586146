// App global variables/mixins

$light-font-color: scale-color($body-font-color, $lightness: 25%);
$lighter-font-color: scale-color($body-font-color, $lightness: 45%);
$lightest-font-color: scale-color($body-font-color, $lightness: 80%);

$facebookColor: #3D5E99;
$googleColor: #cc0000;
$twitterColor: #2CAFF0;
$linkedinColor: #007BB6;
$rssColor: #F6A832;
$scribdColor: #1e7b85;

// Art Deco colors
$artDecoGray6: #737679;

$global-padding: 1.25rem;

$tiny-text: 0.7rem;
$small-text: 0.9rem;
$normal-text: 1rem;
$big-text: 1.1rem;
$huge-text: 1.3rem;

$login-modal-body-height: 310px;

$ss-topbar-divider-height: rem-calc(17px);
$ss-topbar-dropdown-link-hover-bg: #6c6c6c;

// A slightly darker variant of primary-color.
// Required to meet proper WCAG 2.0 color contrast ratio.
$primary-a11y-color: #007DBA;
// Default placeholder color to meet minimum contrast ratio.
$placeholder-a11y-color: #767676;