$base-font-size: 14px;

// This is the default html and body font-size for the base rem value.
$rem-base: $base-font-size;

// Allows the use of rem-calc() or lower-bound() in your settings
@import "vendor/foundation/functions";

// Modernize
@import "./modernize";

// The $base-font-size is 100% while $base-line-height is 150%
// $base-line-height: 150%;

// We use this to control whether or not CSS classes come through in the gem files.
$include-html-classes: true;
// $include-print-styles: true;
$include-html-global-classes: $include-html-classes;

// b. Grid
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-grid-classes: $include-html-classes;
// $include-xl-html-grid-classes: false;

$row-width: rem-calc(960);
// $total-columns: 12;
$column-gutter: rem-calc(20);

// c. Global
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these to define default font stacks
// $font-family-sans-serif: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
// $font-family-serif: Georgia, Cambria, "Times New Roman", Times, serif;
// $font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;

// We use these to define default font weights
$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

// We use these as default colors throughout
$primary-color: #027Eb0;
$secondary-color: #e98325;
$secondary-color-accessible: #aF631d;
$alert-color: #ea6423;
$success-color: #43AC6A;
$warning-color: #f08a24;
$info-color: #a0d3e8;

$white       : #FFFFFF;
$ghost       : #FAFAFA;
$snow        : #F9F9F9;
$vapor       : #F6F6F6;
$white-smoke : #F5F5F5;
$silver      : #EFEFEF;
$smoke       : #EEEEEE;
$gainsboro   : #DDDDDD;
$iron        : #CCCCCC;
$base        : #AAAAAA;
$aluminum    : #999999;
$jumbo       : #888888;
$monsoon     : #777777;
$steel       : #666666;
$charcoal    : #555555;
$tuatara     : #444444;
$oil         : #2d2d2d;
$jet         : #222222;
$black       : #000000;

// We use these to control various global styles
$body-bg: #fff;
$body-font-color: #3b3835;
$body-font-family: $font-family;

// d. Media Query Ranges
// - - - - - - - - - - - - - - - - - - - - - - - - -

$small-range: (0em, 40em);
$medium-range: (40.063em, 58em);
$large-range: (58.063em, 90em);
$xlarge-range: (90.063em, 120em);
$xxlarge-range: (120.063em, 99999999em);

$screen: "only screen";

$landscape: "#{$screen} and (orientation: landscape)";
$portrait: "#{$screen} and (orientation: portrait)";

$small-up: $screen;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})";

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";

// Legacy
$small: $medium-up;
$medium: $medium-up;
$large: $large-up;
$header-font-color: scale-color($body-font-color, $lightness: 10%);
$header-line-height: 1.4;
// $header-top-margin: .2rem;
$header-bottom-margin: .3rem;
// $header-text-rendering: optimizeLegibility;

// We use these to control header font sizes
$h1-font-size: rem-calc(42);
$h2-font-size: rem-calc(35);
$h3-font-size: rem-calc(25);
$h4-font-size: rem-calc(21);
$h5-font-size: rem-calc(16);
$subheader-bottom-margin: .3rem;

// A general <small> styling
$small-font-size: 92%;
$paragraph-margin-bottom: rem-calc(20);
$accordion-navigation-font-color: $body-font-color;
$alert-font-color: $white;


// 05. Buttons
// - - - - - - - - - - - - - - - - - - - - - - - - -
// We use these to build padding for buttons.
$button-tny: rem-calc(8);
$button-sml: rem-calc(10);
$button-med: rem-calc(12);
$button-lrg: rem-calc(14);

// We use these to control button border and hover styles.
$button-border-width: 1px;
// We use this to set default opacity and cursor for disabled buttons.
$button-disabled-opacity: 0.4;

// We use this to set the base for lots of form spacing and positioning styles
$form-spacing: rem-calc(22);
$form-label-font-weight: $font-weight-bold;
$form-label-bottom-margin: 8px;
$input-box-shadow: 0;

// 12. Icon Bar
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these to style the icon-bar and items
$icon-bar-font-color: $white;
$icon-bar-font-size: 11px;
$icon-bar-hover-color: transparent;
$icon-bar-icon-size: 21px;
$icon-bar-image-width: 21px;
$icon-bar-image-height: 21px;
$icon-bar-item-padding: 8px 0 4px 0;

// Controlling default Joyride styles
$joyride-tip-bg: $white;
$joyride-tip-default-width: 30em;
$joyride-tip-padding-horizontal: rem-calc(20);
$joyride-tip-padding: rem-calc(18) $joyride-tip-padding-horizontal rem-calc(24);

// Here, we're setting the tip font styles
$joyride-tip-font-color: $charcoal;
$joyride-tip-font-size: rem-calc(14);
$joyride-tip-header-weight: $font-weight-normal;

// When Joyride is filling the screen, we use this style for the bg
$joyride-screenfill: rgba(0, 0, 0, 0.66);

// 15. Keystrokes
// - - - - - - - - - - - - - - - - - - - - - - - - -
$keystroke-font-color: $body-font-color;

$tabbar-bg: rgba(45,45,45, 1);
$tabbar-height: rem-calc(47);

// Off Canvas Divider Styles
$tabbar-right-section-border: solid 0px scale-color($tabbar-bg, $lightness: 13%);
$tabbar-left-section-border: solid 0px scale-color($tabbar-bg, $lightness: -50%);


// Off Canvas Menu Icon Variables
$tabbar-menu-icon-color: #e6e6e6;
$pagination-li-font-color: $body-font-color;

// 21. Panels
// - - - - - - - - - - - - - - - - - - - - - - - - -
// We use these to control the background and border styles
$panel-bg: scale-color($white, $lightness: 0%);

// We use this % to control how much we darken things on hover
$panel-function-factor: -11%;
$panel-border-color: scale-color($panel-bg, $lightness: $panel-function-factor);

// 23. Progress Bar
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use this to set the progress bar height
$progress-bar-height: rem-calc(6);
$progress-bar-color: #4d4d4d;
$progress-bar-border-style: none;
$progress-bar-border-radius: 0;

// We use these to control the margin & padding
$progress-bar-pad: 0;
$progress-bar-margin-bottom: 0;

// We use these to set the meter colors
$progress-meter-color: #128ece;
$progress-meter-secondary-color: #ffa400;

// 25. Reveal
// - - - - - - - - - - - - - - - - - - - - - - - - -

// We use these to control the style of the reveal overlay.
$reveal-overlay-bg: rgba($black, .76);
$reveal-overlay-bg-old: $black;
$reveal-box-shadow: 0 0 10px rgba($black,.4);

// We use these to style the reveal close button
$reveal-close-font-size: rem-calc(24);
$reveal-close-top: rem-calc(4);
// $reveal-close-side: rem-calc(11);
$reveal-close-color: $body-font-color;
$reveal-close-weight: $font-weight-normal;

// We use these to control the modal border
$reveal-border-style: solid;
$reveal-border-width: 2px;
$reveal-border-color: scale-color($white, $lightness: -20%);

// We use these to control link styles.
$side-nav-link-color: $body-font-color;
$side-nav-link-color-active: scale-color($side-nav-link-color, $lightness: -20%);
$side-nav-font-weight-active: $font-weight-bold;

// 30. Tables
// - - - - - - - - - - - - - - - - - - - - - - - - -

$include-html-table-classes: $include-html-classes;

// These control the background color for the table and even rows
$table-bg: $white;
$table-even-row-bg: $table-bg  ;

// These control the table cell border style
$table-border-style: none;
$table-border-size: 1px;
$table-border-color: $gainsboro;

// These control the table head styles
$table-head-bg: $white  ;
$table-head-font-size: rem-calc(14);
$table-head-font-color: $body-font-color;
$table-head-font-weight: $font-weight-bold;
$table-head-padding: rem-calc(8 10 10);

// These control the row padding and font styles
$table-row-padding: rem-calc(9 10);
$table-row-font-size: rem-calc(14);
$table-row-font-color: $body-font-color;
$table-line-height: rem-calc(18);

// These are for controlling the layout, display and margin of tables
$table-layout: auto;
$table-display: table-cell;
$table-margin-bottom: rem-calc(20);

// 31. Tabs
// - - - - - - - - - - - - - - - - - - - - - - - - -
$tabs-navigation-padding: rem-calc(10);
$tabs-navigation-bg-color: transparent;
$tabs-navigation-active-bg-color: transparent;
$tabs-navigation-hover-bg-color: transparent;
$tabs-navigation-font-color: scale-color($body-font-color, $lightness: 45%);
$tabs-navigation-active-font-color: $body-font-color;
$tabs-navigation-font-size: $rem-base;

// 33. Tooltips
// - - - - - - - - - - - - - - - - - - - - - - - - -
$has-tip-border-bottom: none;
$has-tip-border-bottom-hover: none;

// 34. Top Bar
// - - - - - - - - - - - - - - - - - - - - - - - - -
// Background color for the top bar
$topbar-bg-color: rgba(45,45,45,1);
$topbar-bg: $topbar-bg-color;

// Height and margin
$topbar-height: rem-calc(49px);
$topbar-margin-bottom: 2px;

// Controlling the styles for the title in the top bar
// Style the top bar dropdown elements
$topbar-dropdown-bg: transparent;
$topbar-dropdown-link-color: #e6e6e6;
$topbar-dropdown-link-bg: #505050;

// Set the link colors and styles for top-level nav
$topbar-link-color: #e6e6e6;
$topbar-link-weight: 300;
$topbar-link-font-size: rem-calc(14);
$topbar-link-bg: transparent;
$topbar-link-bg-color-hover: transparent;
$topbar-link-bg-hover: transparent;
$topbar-link-bg-active: transparent;
$topbar-link-bg-active-hover: transparent;
$topbar-button-top: 11px;

// Transitions and breakpoint styles
$topbar-breakpoint: 0;
$topbar-media-query: $small-up;

// Divider Styles
$topbar-divider-border-bottom: solid 1px  scale-color($topbar-bg-color, $lightness: 23%);
$topbar-arrows: false; //Set false to remove the triangle icon from the menu item
