$font-grey: #b4bbd1;
$font-grey-dark: #989fbc;
$font-black: #212240;
$font-dark: #0e0f25;

$grey-mid: #d0d6e6;
$grey-light: #f5f6fa;
$blue-grey: #989fbc;
$grey-dark: #636c8b;

$blue-dark: #027eb0;
$celadon-blue-dark: #026c97;
$blue-dark-transparent: rgba(2, 126, 176, 0.8);
$blue-light: #f2f6fc;
$blue-mid: #ddecf9;

$green-med: #00546f;
$green-dark: #002e3c;

$facebookColor: #3d5e99;
$linkedinColor: #007bb6;
$scribdColor: #1e7b85;

$font-family: "Source Sans Pro", sans-serif;

$box-shadow: 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.19), 0 0 1px 0 rgba(0, 0, 0, 0.04);

$slideview_margin_x: 15px;

/* These colors replicate frontend/styles/colors.css */
$blue-gray-100: #f5f6fa;
$blue-gray-200: #e3e6f0;
$blue-gray-300: #d0d6e6;
$blue-gray-400: #b4bbd1;
$blue-gray-500: #989fbc;
$blue-gray-550: #8c93b1;
$blue-gray-600: #636c8b;
$blue-gray-700: #424766;
$blue-gray-800: #212240;
$blue-gray-900: #0e0f25;

$grey-dark: #989fbc;
$alice-blue-100: #f5fcff;
$alice-blue-200: #f2faff;
$alice-blue-600: #ddf1f9;

$error-red: #bf3131;
$alert-orange: #bf5905;
