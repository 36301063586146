/// Custom styles for Foundation's Joyride components

@if $include-html-joyride-classes {
  $joyride-title-border-padding: 0.2em !default;
  $joyride-title-border: $gainsboro !default;
  $joyride-title-margin-top: rem-calc(4) !default;
  $joyride-title-margin-bottom: rem-calc(18) !default;

  .joyride-tip-guide {
    box-shadow: $reveal-box-shadow;

    &.fixed-position {
      position: fixed;
    }

    .small {
      margin-bottom: 1rem;
      font-size: 0.8rem;
    }
  }

  .joyride-content-wrapper {

    h4 {
      border-bottom: 1px solid $joyride-title-border;
      margin: $joyride-title-margin-top (-$joyride-tip-padding-horizontal) $joyride-title-margin-bottom;
      padding: $joyride-title-border-padding $joyride-tip-padding-horizontal;
    }

    .joyride-next-tip {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
