// Visibility utilities

@mixin responsive-flex {
  &.visible-phone     { display: none; }
  &.visible-tablet    { display: none; }
  &.visible-desktop   { display: flex; }
  &.hidden-phone      { display: flex; }
  &.hidden-tablet     { display: flex; }
  &.hidden-desktop    { display: none; }

  // Phones only
  @media (max-width: 767px) {
    // Show
    &.visible-phone     { display: flex; }
    // Hide
    &.hidden-phone      { display: none; }
    // Hide everything else
    &.hidden-desktop    { display: flex; }
    &.visible-desktop   { display: none; }
  }

  // Tablets & small desktops only
  @media (min-width: 768px) and (max-width: 979px) {
    // Show
    &.visible-tablet    { display: flex; }
    // Hide
    &.hidden-tablet     { display: none; }
    // Hide everything else
    &.hidden-desktop    { display: flex; }
    &.visible-desktop   { display: none; }
  }
}
